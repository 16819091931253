<template>
  <el-dialog :visible="dialogVisible" :show-close="false" @open="open">
    <div slot="title" class="left_top">
      <div class="left_icon">
        <span></span>
        <span>{{title}}</span>
      </div>
    </div>
    <el-form
      label-position="top"
      width="900px"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="ruleForm"
      label-width="80px"
    >
      <el-divider content-position="left">基本信息</el-divider>
      <el-form-item label="企业名称" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="企业状态" prop="enable">
        <el-radio v-model="ruleForm.enable" :label="true">启用</el-radio>
        <el-radio v-model="ruleForm.enable" :label="false">禁用</el-radio>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="ruleForm.remark"
          style="width:100%;"
          type="textarea"
          :rows="2"
          placeholder="请输入备注"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      <el-button @click="closeDialog('ruleForm')">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { api } from '/src/api/base';


export const workEnterpriseAdd = api('')(
  'work_enterprise.add.json'
);
export const workEnterpriseInfo = api('')(
  'work_enterprise.info.json'
);
export const workEnterpriseEdit = api('')(
  'work_enterprise.edit.json'
);

export default {
  components: {

  },
  data () {
    return {

      ruleForm: {
        name: '',
        remark: '',
        enable: true
      },
      rules: {
        name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        enable: [{ required: true, message: '请选择企业状态', trigger: 'blur' }],
      },

    };
  },
  props: {
    dialogVisible: Boolean,

    title: String,
    code: String
  },
  created () {

  },
  methods: {
    open () {
      console.log(this.enable);
      if (this.title === '编辑企业') {
        const params = {
          code: this.code
        };
        workEnterpriseInfo(params).then(res => {
          console.log(res);
          this.ruleForm.enable = res.enable

          this.ruleForm.name = res.name
          this.ruleForm.remark = res.remark
        });

      }

    },
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        console.log(formName);
        if (valid) {
          if (this.title === '编辑企业') {
            const params = {
              code: this.code,
              enable: this.ruleForm.enable,
              name: this.ruleForm.name,
              remark: this.ruleForm.remark
            };
            workEnterpriseEdit(params).then(res => {
              console.log(res);
              this.closeDialog(formName);
            });
          }
          else {
            const params = {
              enable: this.ruleForm.enable,
              name: this.ruleForm.name,
              remark: this.ruleForm.remark
            };
            workEnterpriseAdd(params).then(res => {
              console.log(res);
              this.closeDialog(formName)
            });
          }




        } else {

          return false;
        }
      });
    },
    resetForm (formName) {
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      })
    },
    closeDialog (formName) {
      this.resetForm(formName);
      this.$emit('update:dialogVisible', false);
      this.$emit('func', this.dialogVisible);
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ruleForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  width: 49%;
}

.el-form-item__label {
  padding-bottom: 0 !important;
}
</style>
